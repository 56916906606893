import React from "react";
import '../css/contact.css'
import Layout from "../components/layout"

export default class Contact extends React.Component{
  componentWillMount() {
    // window.scrollTo(0,0)
  }

  render() {
    return(
      <Layout>
        <div className="contact-way">
          <h3>马拉松跑步助手公众号(imarathonrunning)</h3>
          <img alt="马拉松跑步助手" src={'../../images/imarathonrunning.jpg'}/>
          <h3>簇格运动公众服务号(cugefit)</h3>
          <img alt="簇格运动" src={'../../images/cugefit.jpg'}/>
          <h3>商务联系(602967386)</h3>
          <img alt="商务联系" src={'../../images/602967386.jpg'}/>
          <h3>联系小客服(xiwen931016)</h3>
          <img alt="联系小客服" src={'../../images/xiwen.jpg'}/>

        </div>
      </Layout>
    )
  }
}